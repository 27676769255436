"use strict";

angular.module("informaApp")
    .service("RequestQueueHelper", function () {
        return {
            getQueue: function () {
                return {
                    currentRequest: null,
                    nextRequest: null,
                    addRequest: function (request) {
                        if (!this.currentRequest){
                            this.nextRequest = null;
                            this.currentRequest = request;

                            var sender = this;
                            this.currentRequest(function() { sender.invokeNextRequest.apply(sender, []); });
                        } else {
                            this.nextRequest = request;
                        }
                    },
                    invokeNextRequest: function () {
                        this.currentRequest = null;

                        if (this.nextRequest){
                            this.addRequest(this.nextRequest);
                        }
                    }
                };
            }
        }
    });